import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var btn = $('#button');
	  $(window).scroll(function() {
		if ($(window).scrollTop() > 300) {
		 $('#button').addClass('show');
		} else {
		 $('#button').removeClass('show');
		}
	  });
  }

}
